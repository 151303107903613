<template>
  <div class="enConstruction">
    <div class="vid">
      <video id="video" src="../assets/background5.mp4" autoplay="true" preload="auto" loop muted></video>
      <div class="bleu"></div>
    </div>

    <h3><span id="titre"></span><span id="clignote"> |</span></h3>
    
  </div>
</template>

<style scoped>
h3{
  position: absolute;
	color: white;
	text-shadow: 8px 8px 6px black;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
}

#titre{
  font-family: "xenotron";
  font-size: 40px;
}

#clignote  {
  font-size: 50px;
  animation-duration: .8s;
  animation-name: clignoter;
  animation-iteration-count: infinite;
  transition: none;
}

@keyframes clignoter {
  0%   { opacity:1; }
  50%   {opacity:0; }
  100% { opacity:1; }
}
</style>

<script>

export default {
  name: 'EnConstruction',
  data(){
    return {
      text: `En construction`,
      i: 0,
      speed: 100,
    }
  },
  mounted() {
    document.getElementById("titre").innerHTML = "";
    this.typeWriter();
    window.scrollTo(0, 0)
  },
  methods: {
    typeWriter() {
      if (this.i < this.text.length) {
        document.getElementById("titre").innerHTML += this.text.charAt(this.i);
        this.i++;
        setTimeout(this.typeWriter, this.speed);
      }
    }
  },
}
</script>